import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { colors } from '../theme/colors';

export const toastMessage = (message, type = 'info') => {
  toast[type](message);
};

export const SweetAlert = Swal.mixin({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  customClass: {
    popup: 'popup-class',
  },
  showLoaderOnConfirm: true,
  showCancelButton: true,
  confirmButtonColor: colors.primary,
  cancelButtonColor: colors.red,
  confirmButtonText: 'Yes, Apply Changes!',
});

export const amountWithcommas = (amount) => {
  const internationalNumberFormat = new Intl.NumberFormat('en-US');
  return internationalNumberFormat.format(amount);
};
export const calculatePercentage = (amount, percentage) =>
  (amount / 100) * percentage;

export const createFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    if (object[key]) formData.append(key, object[key]);
    return formData;
  }, new FormData());

export const formatUserRoleText = (string) => {
  string = string.charAt(0).toUpperCase() + string.slice(1);
  return string.split(/(?=[A-Z])/).join(' ');
};

export const roundAmountToTwoDecimal = (amount) =>
  Math.round((amount + Number.EPSILON) * 100) / 100;

export const capitalizeFirstLetterOfString = (name) =>
  name?.charAt(0).toUpperCase() + name.slice(1);

export const generateAvatar = (text) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  let initials;
  canvas.width = 60;
  canvas.height = 60;
  const name = text;
  const nameSplit = name.split(' ');
  if (nameSplit?.length > 1) {
    initials =
      nameSplit[0].charAt(0).toUpperCase() +
      nameSplit[1].charAt(0).toUpperCase();
  } else {
    initials = nameSplit[0].charAt(0).toUpperCase();
  }

  const backgroundColor = `#${Math.floor(Math.random() * 16777215).toString(
    16
  )}`;

  // Draw background
  context.fillStyle = backgroundColor;
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  context.font = 'bold 30px Roboto';
  context.fillStyle = '#FFF';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(initials, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL('image/png');
};

export const useSharedState = (key, initialValue) => {
  let state;
  const queryClient = useQueryClient();
  const setState = (value) => {
    queryClient.setQueryData(key, value);
  };

  if (!initialValue) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ({ data: state } = useQuery(key, () => queryClient.getQueryData(key)));
  } else {
    setState(initialValue);
  }

  return [state, setState];
};

export const isNumberValid = (number) => (!Number.isNaN(number) ? number : 0);

export const addZeroes = (num) =>
  num.toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits: 2,
  });

export const formatDate = (date, format = 'LL') => moment(date).format(format);

export const phoneRegexp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
