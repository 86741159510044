import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-adornedEnd ': {
      paddingRight: theme.spacing(2),
    },
  },
  label: {
    color: theme.palette.text.info,
  },
  customInputFieldStyle: {
    border: '0.5px',
    borderRadius: '20px',
    height: '65px',
    fontSize: '16px',
    // width:"150px",
    // marginTop: theme.spacing(-6),
    backgroundColor: theme.palette.text.light,
    color: theme.palette.text.dark,
    '& .MuiIconButton-root': {
      padding: '0px',
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.spacing(2),
      fontSize: '10.5px',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0,0,0,0), 
      height: '57px',
    },
  },
}));
